import React, { useCallback, useEffect, useState } from 'react';
import cls from 'classnames';
import { Button, Upload } from 'antd';
import * as XLSX from 'xlsx';
import { UploadFile } from 'antd/lib/upload/interface';
import locale from '@@/_new_src_/local/common/en_US';
import ExcelImportIcon from '@@/assets/images/excel-import.png';
import WarningText from '@@/_new_src_/components/WarningText';
import { IUploadExcelProps } from '@@/_new_src_/types/features';
import './index.less';
import DOMPurify from 'dompurify';

const { Dragger } = Upload;

const UploadFiles = (props: IUploadExcelProps) => {
  const { uploadExcel: uploadExcelLocale } = locale;
  const {
    getFileList,
    clearError,
    getExcelData,
    warningText,
    uploadTipTexts,
    error = false,
    loading = false,
    disabled = false,
    updateFileList = { update: false },
    sheetName,
  } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    const { update, fileList = [] } = updateFileList;
    if (update) {
      setFileList(fileList);
    }
  }, [updateFileList]);

  const readAndFormatExcelData = (file: File) => {
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(file);

    fileReader.onload = event => {
      try {
        const data = event.target && event.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetNames = workbook.SheetNames;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let outputData: any[] = [];
        if (sheetName) {
          if (sheetNames.includes(sheetName)) {
            outputData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
          } else {
            // eslint-disable-next-line no-console
            console.log(`The correct sheet name was not found：${sheetName}`);
          }
        } else {
          sheetNames.forEach(name => {
            outputData = outputData.concat(XLSX.utils.sheet_to_json(workbook.Sheets[name]));
          });
        }
        getExcelData(outputData);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Error while get data from importing...', e);
      }
    };
  };

  const beforeUnloadFun = (file: File) => {
    readAndFormatExcelData(file);
    return false;
  };

  const handleChangeUploadFile = useCallback(
    (info: { file: UploadFile; fileList: UploadFile[] }) => {
      if (info.file) {
        setFileList(info.fileList.slice(-1));
        getFileList(info.fileList.slice(-1));
        clearError();
      }
    },
    [clearError, getFileList],
  );

  return (
    <div className="upload-excel-wrapper">
      <Dragger
        className={cls('importSection', {
          'importSection-error': error,
        })}
        beforeUpload={beforeUnloadFun}
        onChange={handleChangeUploadFile}
        fileList={fileList}
        multiple={false}
        accept=".xlsx"
        disabled={disabled}
      >
        <div className="ant-upload-drag-icon">
          <div className="import-button-wrapper">
            <Button loading={loading} disabled={disabled}>
              <img src={ExcelImportIcon} alt="" />
              <span>{uploadExcelLocale.import}</span>
            </Button>
          </div>
        </div>
        <p
          className="ant-upload-text"
          dangerouslySetInnerHTML={{ __html: uploadExcelLocale.operationTip }}
        />
        {uploadTipTexts &&
          uploadTipTexts.length > 0 &&
          uploadTipTexts.map(item => {
            return (
              <p
                className="ant-upload-text"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item) }}
              />
            );
          })}
        <WarningText warningText={warningText} />
      </Dragger>
    </div>
  );
};

export default UploadFiles;
