import { ajax } from '@@/common/ajax';
import { getApiV2Path } from '@@/api/utils';
import { IPostCalibrateProps } from '@@/_new_src_/store/calibrateIndicatorsSlice/interface';

export const postCalibrateValidation = (params: IPostCalibrateProps) =>
  ajax.post(getApiV2Path('/cycles/calibrate-validation'), params);

export const postAssessmentRecords = (params: IPostCalibrateProps) =>
  ajax.post(getApiV2Path('/cycles/assessment-records'), params);

export const getCanCalibrateCycle = () => ajax.get(getApiV2Path('/cycles/can-calibrate-cycle'));
