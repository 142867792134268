import React, { memo } from 'react';
import PerfModal from '../../PerfModal';
import './index.less';
import { useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { formatRangeDuration } from '@@/_new_src_/utils/common/date';
import DOMPurify from 'dompurify';

const CopyExpectationModal = props => {
  const {
    visible,
    onOk,
    onCancel,
    projectName,
    expectationText,
    duration = { startTime: '', endTime: '' },
  } = props;
  const {
    setReview: {
      form: {
        expectation: { copyModal },
      },
    },
  } = useSelector(selectLocaleResource);

  const onClickCopy = () => {
    onCancel();
    onOk();
  };

  return (
    <PerfModal
      className="component-copy-expectation-modal"
      visible={visible}
      onCancel={onCancel}
      onOk={onClickCopy}
      closable={false}
      okText={copyModal.okButtonText}
      cancelText={copyModal.cancelButtonText}
      title={copyModal.titleText}
    >
      <div className="modal-content">
        <div className="tip">{copyModal.projectTip}</div>
        <div className="info">
          <div>Project:&nbsp;{projectName},&nbsp;</div>
          <div>Duration:&nbsp;{formatRangeDuration(duration.startTime, duration.endTime)}</div>
        </div>
        <div className="expectation-text">
          <div
            className="global-rich-text"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(expectationText) }}
          />
        </div>
      </div>
    </PerfModal>
  );
};

export default memo(CopyExpectationModal);
