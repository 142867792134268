import React, { useEffect, useMemo, useState } from 'react';
import { get, includes, isEmpty, isNumber, trim, values } from 'lodash';
import { Button, Tooltip } from 'antd';
import locale from '@@/_new_src_/local/calibrateIndicator/en_US';
import { UploadFile } from 'antd/lib/upload/interface';
import { IImportError, IImportExcel } from '@@/_new_src_/types/calibrateIndicators';
import { CYCLE_SCORE_RATING } from '@@/_new_src_/constants/myCycles';
import {
  ERROR_CODE,
  initialUploadFileError,
  REASON_LENGTH,
} from '@@/_new_src_/constants/calibrateIndicatorsEnum';
import UploadExcel from '@@/_new_src_/components/UploadExcel';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCanCalibrateCycleAsync,
  postAssessmentRecordsAsync,
  postCalibrateValidationAsync,
} from '@@/_new_src_/store/calibrateIndicatorsSlice/asyncThunk';
import {
  calibrateIndicatorsStore,
  canCalibrateCycleStore,
  setCalibrateValidationResponse,
  setUploadAssessmentRecordsResponse,
} from '@@/_new_src_/store/calibrateIndicatorsSlice';
import {
  IErrorDetail,
  IPostCalibrateProps,
} from '@@/_new_src_/store/calibrateIndicatorsSlice/interface';
import perfMessage from '@@/_new_src_/components/perfMessage';
import './index.less';
import {
  CONFIRM_MODAL_TYPE_INFO,
  TBP_BULK_UPLOAD_INDICATOR_DURATION,
} from '@@/_new_src_/constants/common';
import ConfirmModal from '@@/_new_src_/components/ConfirmModal';
import {
  confirmModalType,
  setConfirmModalStatus,
  setConfirmModalType,
} from '@@/_new_src_/store/commonSlice';
import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import DOMPurify from 'dompurify';

const calibrateIndicators = () => {
  const dispatch = useDispatch();
  const {
    title: titleLocale,
    info: infoLocale,
    errorMessage: errorMessageLocale,
    warningText: warningTextLocale,
    upload: uploadLocale,
  } = locale;

  const currentModalType = useSelector(confirmModalType);
  const { inH1CycleCalibrateStage, inCycleCalibrateStage, getCanCalibrateCycleLoading } =
    useSelector(canCalibrateCycleStore);

  const { calibrateValidationResponse, uploadAssessmentRecordsResponse } =
    useSelector(calibrateIndicatorsStore);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadFileError, setUploadFileError] = useState<IImportError>(initialUploadFileError);
  const [postParams, setPostParams] = useState<IPostCalibrateProps | null>(null);
  useEffect(() => {
    dispatch(getCanCalibrateCycleAsync());
  }, []);

  const getFileList = (file: UploadFile[]) => {
    setFileList(file);
  };

  const updateFileList = useMemo(() => {
    if (uploadAssessmentRecordsResponse.success) {
      return {
        update: true,
        fileList: [],
      };
    }
    return {
      update: false,
    };
  }, [uploadAssessmentRecordsResponse.success]);

  const getExcelData = (data: Array<IImportExcel>) => {
    const missFiled: Array<string> = [];
    const invalidEmployeeId: Array<string> = [];
    const invalidReason: Array<string> = [];
    const invalidRating: Array<string> = [];

    if (isEmpty(data)) {
      setUploadFileError({
        code: ERROR_CODE.INVALID_FILE_ERROR,
        errorItems: [],
      });
    } else {
      const excelData = data.map(item => {
        const employeeId = trim(get(item, 'TWer’s ID'));
        const rating = trim(get(item, 'Calibrated Indicator'));
        const reason = trim(get(item, 'Reason for change'));
        const rowNumber = trim(get(item, '__rowNum__'));
        const currentRowNumber = +rowNumber + 1;
        const postParams = {
          lineNumber: currentRowNumber,
          employeeId,
          rating,
          reason,
        };

        if (isEmpty(employeeId) || isEmpty(reason) || isEmpty(rating)) {
          missFiled.push(`${currentRowNumber}`);
          return postParams;
        }
        if (!isNumber(+employeeId)) {
          invalidEmployeeId.push(employeeId);
          return postParams;
        }
        if (reason.length < REASON_LENGTH.MIN || reason.length > REASON_LENGTH.MAX) {
          invalidReason.push(`${currentRowNumber}`);
          return postParams;
        }
        if (!includes(values(CYCLE_SCORE_RATING), rating)) {
          invalidRating.push(`${currentRowNumber}`);
          return postParams;
        }

        return postParams;
      });

      if (missFiled.length > 0) {
        setUploadFileError({
          code: ERROR_CODE.MISS_FIELD_ERROR,
          errorItems: missFiled,
        });
        return;
      }
      if (invalidEmployeeId.length > 0) {
        setUploadFileError({
          code: ERROR_CODE.INVALID_EMPLOYEE_ID_ERROR,
          errorItems: invalidEmployeeId,
        });
        return;
      }
      if (invalidReason.length > 0) {
        setUploadFileError({
          code: ERROR_CODE.INVALID_REASON_ERROR,
          errorItems: invalidReason,
        });
        return;
      }
      if (invalidRating.length > 0) {
        setUploadFileError({
          code: ERROR_CODE.INVALID_INPUT_ERROR,
          errorItems: invalidRating,
        });
        return;
      }

      if (excelData.length > 0) {
        const params = { calibrateItems: excelData };
        dispatch(postCalibrateValidationAsync(params));
        setPostParams(params);
      }
    }
  };

  const errorInfo = useMemo(() => {
    let errorDetail: IErrorDetail = initialUploadFileError;
    const localCode = get(uploadFileError, 'code');
    if (isEmpty(localCode)) {
      const validationCode = get(calibrateValidationResponse, 'code');
      if (!isEmpty(validationCode)) {
        errorDetail = calibrateValidationResponse;
      }
      const uploadCode = get(uploadAssessmentRecordsResponse, 'code');
      if (!isEmpty(uploadCode) && uploadAssessmentRecordsResponse.rejected) {
        errorDetail = uploadAssessmentRecordsResponse;
      }
    } else {
      errorDetail = uploadFileError;
    }

    const hasError =
      !isEmpty(errorDetail.code) && errorDetail.code !== ERROR_CODE.SUCCESS && fileList.length > 0;

    return { hasError, errorDetail };
  }, [fileList, uploadFileError, calibrateValidationResponse, uploadAssessmentRecordsResponse]);

  const getErrorMessage = ({ code, errorItems }: IErrorDetail) => {
    switch (code) {
      case ERROR_CODE.INVALID_FILE_ERROR:
        return errorMessageLocale[code];
      case ERROR_CODE.MISS_FIELD_ERROR:
      case ERROR_CODE.INVALID_INPUT_ERROR:
      case ERROR_CODE.INVALID_REASON_ERROR:
        const inputMessage = errorItems.map(item => `Row ${+item}`).join(', ');
        return errorMessageLocale[code] + '<br/>' + inputMessage;
      default:
        const message = errorItems.join(', ');
        return get(errorMessageLocale, code) + '<br/>' + message;
    }
  };

  const getIsUploadBtnDisabled = useMemo(() => {
    const isFileListEmpty = fileList.length < 1;
    const isImportExcelInProgress = calibrateValidationResponse.loading;
    return (
      isFileListEmpty ||
      errorInfo.hasError ||
      !inCycleCalibrateStage ||
      isImportExcelInProgress ||
      isEmpty(postParams)
    );
  }, [fileList, errorInfo, calibrateValidationResponse.loading, postParams]);

  const handleReset = () => {
    dispatch(setCalibrateValidationResponse({}));
    dispatch(setUploadAssessmentRecordsResponse({}));
    setPostParams(null);
    setUploadFileError(initialUploadFileError);
  };

  useEffect(() => {
    const isFileListEmpty = fileList.length < 1;
    isFileListEmpty && handleReset();
  }, [fileList]);

  const uploadExcel = () => {
    dispatch(setConfirmModalStatus(true));
    dispatch(setConfirmModalType(CONFIRM_MODAL_TYPE_INFO.TBP_BULK_UPLOAD_INDICATORS));
  };

  useEffect(() => {
    if (uploadAssessmentRecordsResponse.success) {
      const indicatorSize = get(postParams, 'calibrateItems.length', 0);
      perfMessage.success(uploadLocale.successTip(indicatorSize));
      handleReset();
    }
  }, [uploadAssessmentRecordsResponse.success, postParams]);

  const handleOkUploadIndicators = () => {
    if (!isEmpty(postParams) && !getIsUploadBtnDisabled) {
      dispatch(postAssessmentRecordsAsync(postParams));
    }
  };

  const uploadConfirmModalProps = () => {
    const date = inH1CycleCalibrateStage
      ? TBP_BULK_UPLOAD_INDICATOR_DURATION.H1.endDate
      : TBP_BULK_UPLOAD_INDICATOR_DURATION.H2.endDate;

    const {
      title,
      content: contentLocale,
      okBtnText,
      cancelBtnText,
    } = get(uploadLocale, 'confirmModal');

    return {
      title: title,
      content: contentLocale(date),
      okText: okBtnText,
      cancelText: cancelBtnText,
      handleOkFunc: handleOkUploadIndicators,
    };
  };

  return (
    <LoadingWrapper loading={getCanCalibrateCycleLoading}>
      <div className={'calibrate-indicators'}>
        <div className="main">
          <div className="title" dangerouslySetInnerHTML={{ __html: titleLocale }} />
          <div className="info">
            <div className="describe" dangerouslySetInnerHTML={{ __html: infoLocale.describe }} />
            <p dangerouslySetInnerHTML={{ __html: infoLocale.step }} />
            <p dangerouslySetInnerHTML={{ __html: infoLocale.step1 }} />
            <p dangerouslySetInnerHTML={{ __html: infoLocale.step2 }} />
            <p dangerouslySetInnerHTML={{ __html: infoLocale.step3 }} />
          </div>
          <UploadExcel
            getFileList={getFileList}
            clearError={handleReset}
            getExcelData={getExcelData}
            warningText={warningTextLocale}
            error={errorInfo.hasError}
            loading={calibrateValidationResponse.loading}
            disabled={uploadAssessmentRecordsResponse.loading || !inCycleCalibrateStage}
            updateFileList={updateFileList}
            sheetName={'Input Sheet'}
          />
          {errorInfo.hasError && (
            <div
              className="import-error-msg"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(getErrorMessage(errorInfo.errorDetail)),
              }}
            />
          )}
          <div className="upload-button-wrapper">
            <Tooltip title={!inCycleCalibrateStage && uploadLocale.toolTip}>
              <Button
                disabled={getIsUploadBtnDisabled}
                className="upload-button"
                onClick={uploadExcel}
                loading={uploadAssessmentRecordsResponse.loading}
              >
                <i className="ri-swap-fill upload-icon" />
                <span>{uploadLocale.buttonText}</span>
              </Button>
            </Tooltip>
          </div>
        </div>
        {currentModalType === CONFIRM_MODAL_TYPE_INFO.TBP_BULK_UPLOAD_INDICATORS && (
          <ConfirmModal {...uploadConfirmModalProps()} />
        )}
      </div>
    </LoadingWrapper>
  );
};

export default calibrateIndicators;
