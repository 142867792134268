import { IV2Project } from '@@/_new_src_/types/common';
import { IProject } from '@@/_new_src_/store/commonSlice/interface';
import {
  ASSESSMENT_NON_ENGAGED_DEADLINE,
  MONTH_NUMBER,
  OVERDUE_DEADLINE,
  REQUEST_TO_EDIT_DEADLINE,
} from '@@/_new_src_/constants/common';
import { FEEDBACK_STATUS } from '@@/_new_src_/constants/feedback';
import { formatRangeDuration, getMonthDay } from '@@/_new_src_/utils/common/date';
import { IDuration } from '@@/_new_src_/store/userSlice/interface';
import dayjs from 'dayjs';

const isH1StageForCycle = (cycleDurationEndTime: string) => {
  const getCurrentMonth = dayjs.utc(new Date(cycleDurationEndTime)).month();
  return getCurrentMonth === MONTH_NUMBER.June;
};

export const isH1StageForCurrentUTCTime = () => {
  const getCurrentMonth = dayjs.utc(new Date()).month();
  return getCurrentMonth >= MONTH_NUMBER.January && getCurrentMonth < MONTH_NUMBER.August;
};

export const formatV2Project = (project: IProject) => {
  const { accountId = '', assignmentId = '', projectName = '' } = project;
  return {
    account: accountId,
    assignmentId: assignmentId,
    name: projectName,
  };
};

export const formatV2ProjectListData = (projectList: IProject[]) => {
  return projectList.map(project => formatV2Project(project));
};

export const getV1ProjectParams = (project: IV2Project) => {
  const { account = '', assignmentId = '', name = '' } = project;
  return {
    projectAccount: account,
    projectId: assignmentId,
    projectName: name,
  };
};

export const getRequestToEditTips = (
  isFirstHalfOfYear: boolean,
  isProbation: boolean,
  cycleEndTime: string,
  p1: string,
  p2: string,
  p3?: string,
) => {
  let deadline = '';
  if (isProbation) {
    const date = new Date(cycleEndTime);
    date.setMonth(date.getMonth() + 1);
    deadline = getMonthDay(date);
  } else {
    deadline = isFirstHalfOfYear ? REQUEST_TO_EDIT_DEADLINE.H1 : REQUEST_TO_EDIT_DEADLINE.H2;
  }

  let overdueText = p1 + deadline + p2;

  if (p3) {
    overdueText += deadline + p3;
  }

  return overdueText;
};

export const getFeedbackStatus = (status: string) => {
  const { PENDING, DRAFTED, SUBMITTED, NOT_PROVIDED, CANCELLED } = FEEDBACK_STATUS;
  return {
    isDraftedFeedback: status === DRAFTED,
    isSubmittedFeedback: status === SUBMITTED,
    isPendingFeedback: status === PENDING,
    isCancelledFeedback: status === CANCELLED,
    isNotProvidedFeedback: status === NOT_PROVIDED,
  };
};

export const getOverdueDate = () => {
  if (isH1StageForCurrentUTCTime()) {
    return OVERDUE_DEADLINE.H1;
  } else {
    return OVERDUE_DEADLINE.H2;
  }
};

export const getOverdueDateByCycle = (isFirstHalfOfYear: boolean) => {
  if (isFirstHalfOfYear) {
    return OVERDUE_DEADLINE.H1;
  } else {
    return OVERDUE_DEADLINE.H2;
  }
};

export const getAssessmentNonEngagedDate = (duration: IDuration) => {
  const { startTime = '', endTime = '' } = duration;
  const assessmentNonEngagedDuration = formatRangeDuration(startTime, endTime);

  if (isH1StageForCycle(endTime)) {
    return {
      stage: ASSESSMENT_NON_ENGAGED_DEADLINE.H1,
      duration: assessmentNonEngagedDuration,
    };
  } else {
    return {
      stage: ASSESSMENT_NON_ENGAGED_DEADLINE.H2,
      duration: assessmentNonEngagedDuration,
    };
  }
};
