import GoBackBanner from '@@/_new_src_/features/GoBackBanner';
import { formatInputFun, formatInputTextNode } from '@@/_new_src_/utils';
import { dateWithYearMonthDay } from '@@/_new_src_/constants/dateFormatter';
import ElasticCollapse from '@@/_new_src_/components/ElasticCollapse';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import feedbackLocale from '@@/_new_src_/local/feedback/en_US';
import { UserInfoContext } from '@@/context/userInfo.context';
import { useSelector } from 'react-redux';
import { feedbackDetail } from '@@/_new_src_/store/V2FeedbackSlice';
import { useHistory } from 'react-router';
import './index.less';
import cls from 'classnames';
import ExpectationItem from '@@/_new_src_/features/ExpectationItem';
import FeedbackSwitcher from '../FeedbackSwitcher';
import { V2_READ_FEEDBACK } from '@@/common/constant/qualaroo';
import { trigSurvey } from '@@/common/qualarooTrigger';
import {
  formatRangeDuration,
  formatTimeFun,
  getNewDateFormatByProbation,
} from '@@/_new_src_/utils/common/date';
import { FEEDBACK_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import DOMPurify from 'dompurify';

const InternalDetail = () => {
  const history = useHistory();

  const [isExpectationVisible, setIsExpectationVisible] = useState(false);

  const {
    feedback: {
      noInput: noInputLocale,
      noInputFromRequester: noInputFromRequesterLocale,
      feedbackDetail: {
        backGiveList: backGiveListLocale,
        backRequestList: backRequestListLocale,
        header: headerLocale,
        project: projectLocale,
        duration: durationLocale,
        dueDate: dueDateLocale,
        completionDate: completionDateLocale,
        contextTitle: contextTitleLocale,
      },
      viewExpectation: { title, description },
      showExpectationToggle: { show, hide },
    },
  } = feedbackLocale;

  const { userId } = useContext(UserInfoContext);

  const {
    cycle: { projectName, duration, probation },
    feedback: {
      providerId,
      requestName,
      providerName,
      dueDate,
      updatedAt,
      questions,
      expectations,
    },
    formatContext,
  } = useSelector(feedbackDetail);

  const isGiveFeedback = useMemo(() => {
    return (userId && +userId === +providerId) as boolean;
  }, [userId, providerId]);

  const getHeader = useMemo(() => {
    const curName = isGiveFeedback ? requestName : providerName;
    return headerLocale(isGiveFeedback, curName);
  }, [isGiveFeedback, requestName, providerName]);

  const getBackTitle = useMemo(() => {
    return isGiveFeedback ? backGiveListLocale : backRequestListLocale;
  }, [isGiveFeedback]);

  const getNoInput = useMemo(() => {
    return isGiveFeedback ? noInputFromRequesterLocale : noInputLocale;
  }, [isGiveFeedback]);

  const renderQuestionsDom = useCallback(() => {
    return questions.map(question => {
      const { id, htmlTagQuestion, content } = question;
      return (
        <div className={'question-info'} key={id}>
          <div
            className={'question'}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(htmlTagQuestion),
            }}
          />
          <div
            className={'answer'}
            dangerouslySetInnerHTML={{
              __html: formatInputFun(
                DOMPurify.sanitize(formatInputTextNode(content)) ||
                  `<div class='no-input'>${noInputLocale}</div>`,
              ),
            }}
          />
        </div>
      );
    });
  }, [questions]);

  const renderExpectationTemplate = (visible: boolean) => {
    return (
      visible && (
        <div className={cls('expectation-wrapper', { 'hide-expectation': !isExpectationVisible })}>
          <div className="expectation-icon">
            <div className="inner-expectation-icon"></div>
          </div>
          <div className="expectation-area">
            <div className="info-area">
              <div className="title">{title}</div>
              <div className="content">
                <span className="description">{description}</span>
              </div>
            </div>
            <div className="scrolling-area">
              {expectations?.map((item, index) => (
                <ExpectationItem
                  key={index}
                  title={item.title}
                  content={item.content}
                  categoryIds={item.categoryIds}
                />
              ))}
            </div>
          </div>
        </div>
      )
    );
  };

  const goBackPage = () => {
    trigSurvey(V2_READ_FEEDBACK);
    history.push(FEEDBACK_PAGE_LINK.INDEX);
  };

  const handleOnClick = () => {
    setIsExpectationVisible(!isExpectationVisible);
  };

  const handleOnKeyPress = () => {
    setIsExpectationVisible(!isExpectationVisible);
  };

  return (
    <div className={'internal-detail'}>
      <GoBackBanner title={getBackTitle} goToBackFun={goBackPage} onKeyPress={goBackPage} />
      <div className="header">{getHeader}</div>
      <div className="basic-group">
        <div className={'basic-item project'}>
          <b>{projectLocale}</b>
          <span>{projectName}</span>
        </div>
        <div className={'basic-item duration'}>
          <b>{durationLocale}</b>
          <span>
            {formatRangeDuration(duration.startTime, duration.endTime, {
              format: getNewDateFormatByProbation(probation),
            })}
          </span>
        </div>
      </div>
      <div className="basic-group">
        <div className={'basic-item due-date'}>
          <b>{dueDateLocale}</b>
          <span>{formatTimeFun(dueDate, dateWithYearMonthDay)}</span>
        </div>
        <div className={'basic-item due-date'}>
          <b>{completionDateLocale}</b>
          <span>{formatTimeFun(updatedAt, dateWithYearMonthDay)}</span>
        </div>
      </div>
      <div className={'content-and-expectation-block'}>
        <div className="content-left">
          <div className="main">
            <div className="context">
              <div className={'title-block'}>
                <h1 className="context-title">{contextTitleLocale}</h1>
                {expectations?.length !== 0 && (
                  <div
                    className={'expectation-display-toggle'}
                    onClick={handleOnClick}
                    tabIndex={0}
                    aria-label={'Show expectation toggle'}
                    onKeyPress={handleOnKeyPress}
                  >
                    <div className={'display-info'}>{isExpectationVisible ? hide : show}</div>
                    <i className={isExpectationVisible ? 'ri-eye-off-line' : 'ri-eye-line'}></i>
                  </div>
                )}
              </div>
              <ElasticCollapse
                className="context-info"
                content={
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        DOMPurify.sanitize(formatContext) ||
                        `<div class="no-input">${getNoInput}</div>`,
                    }}
                  />
                }
              />
            </div>
            {questions.length > 0 && <div className="question-list">{renderQuestionsDom()}</div>}
          </div>
          <FeedbackSwitcher />
        </div>
        {renderExpectationTemplate(isExpectationVisible)}
      </div>
    </div>
  );
};

export default InternalDetail;
