import React from 'react';
import './index.less';
import AttachmentBtn from '@@/_new_src_/features/AttachmentBtn';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { IMyCyclesLocale } from '@@/_new_src_/types/myCycles';
import {
  cycleInformation,
  isOperationCycle,
  setCycleDisplayPart,
} from '@@/_new_src_/store/myCyclesSlice';
import { CYCLE_DISPLAY_PART } from '@@/_new_src_/constants/myCycles';
import { useHistory } from 'react-router';
import { get } from 'lodash';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import { goToCycleFormPage } from '@@/_new_src_/utils/feature/cycle';
import { useLocation } from 'react-router-dom';
import { formatRangeDuration } from '@@/_new_src_/utils/common/date';
import DOMPurify from 'dompurify';

const CycleInformation = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    currentCycleDetail: { isTwer, isNewCycle, createBySystem, cycleVersion, id: cycleId },
  } = useSelector(cycleDetailByVersion);

  const showOperation = useSelector(isOperationCycle);
  const currentCycleInformation = useSelector(cycleInformation);

  const { performancePartner, additionalPartners, project, duration } = currentCycleInformation;

  const content = get(currentCycleInformation, 'context.content', '') as string;
  const attachmentName = get(currentCycleInformation, 'context.attachmentName', '') as string;
  const attachmentStorageUrl = get(
    currentCycleInformation,
    'context.attachmentStorageUrl',
    '',
  ) as string;

  const {
    myCycles: { cycleInformation: locale },
  } = (useSelector(selectLocaleResource) as IMyCyclesLocale) || {};

  const editInfoOnClick = () => {
    if (!isNewCycle) {
      const goToCycleFormPageLink = goToCycleFormPage({ cycleVersion, id: cycleId });
      history.push(goToCycleFormPageLink);
    }
    dispatch(setCycleDisplayPart(CYCLE_DISPLAY_PART.INFORMATION_FORM));
  };
  const cycleInfosLayout = () => {
    return createBySystem ? (
      <>
        <div className="field-block-group partners">
          <div className="field-block performance-partner">
            <p className="name">{locale.performancePartner}</p>
            <p className="value">{performancePartner?.name}</p>
          </div>
          <div className="field-block duration">
            <p className="name">{locale.duration}</p>
            <p className="value">
              {duration &&
                duration?.startTime &&
                formatRangeDuration(duration?.startTime, duration?.endTime)}
            </p>
          </div>
          <div className="field-block additional-partners">
            <p className="name">{locale.additionalPartners}</p>
            <p className="value">
              {additionalPartners && additionalPartners.length !== 0
                ? additionalPartners.map(p => p.name).join(', ')
                : locale.na}
            </p>
          </div>
        </div>
      </>
    ) : (
      <>
        <div className="field-block-group partners">
          <div className="field-block performance-partner">
            <p className="name">{locale.performancePartner}</p>
            <p className="value">{performancePartner?.name}</p>
          </div>
          <div className="field-block additional-partners">
            <p className="name">{locale.additionalPartners}</p>
            <p className="value">
              {additionalPartners && additionalPartners.length !== 0
                ? additionalPartners.map(p => p.name).join(', ')
                : 'N/A'}
            </p>
          </div>
        </div>
        <div className="field-block-group project-and-duration">
          <div className="field-block project">
            <p className="name">{locale.project}</p>
            <p className="value">{project?.name}</p>
          </div>
          <div className="field-block duration">
            <p className="name">{locale.duration}</p>
            <p className="value">
              {duration &&
                duration?.startTime &&
                formatRangeDuration(duration?.startTime, duration?.endTime)}
            </p>
          </div>
        </div>
      </>
    );
  };
  const location = useLocation();
  const isInMyCyclesPage = location.pathname.includes('/performance/cycles');
  return (
    <div className="cycle-information">
      <p className="title">{locale.title}</p>
      <div className="information-area">
        <div className="information-showing-area">
          {isInMyCyclesPage && createBySystem && isTwer && (
            <div className={'auto-cycle-tip'}>
              <i className="ri-information-fill"></i>
              <div className={'autoTip'} dangerouslySetInnerHTML={{ __html: locale.autoTip }}></div>
            </div>
          )}
          <div className={'cycle-other-information-area'}>{cycleInfosLayout()}</div>
          {(content || (attachmentName && attachmentStorageUrl)) && (
            <div className="cycle-context-area">
              <p className="name">{locale.context}</p>
              {content && (
                <div
                  className="global-rich-text value"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
                />
              )}
              {attachmentName && attachmentStorageUrl && (
                <AttachmentBtn title={attachmentName} url={attachmentStorageUrl} />
              )}
            </div>
          )}
        </div>
        {showOperation && (
          <div className="information-interaction-area">
            <div className="edit-btn" onClick={editInfoOnClick}>
              <i className="ri-edit-line edit-icon" />
              {locale.editBtn}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CycleInformation;
