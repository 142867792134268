/* eslint-disable max-lines */
import { useMount } from 'ahooks';
import { Button, Dropdown, Menu } from 'antd';
import cls from 'classnames';
import { get, isEmpty, isNil } from 'lodash';
import queryString from 'query-string';
import React, {
  isValidElement,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CYCLE_STATUS_ENUM, CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { cancelReview } from '@@/api/review';
import ChangeHistoryIcon from '../../../assets/images/change-history-icon.svg';
import DeleteGreyIcon from '../../../assets/images/delete-grey.svg';
import DeleteRedIcon from '../../../assets/images/delete-red.svg';
import EditBlueIcon from '../../../assets/images/edit-blue.svg';
import EllipsisIcon from '../../../assets/images/ellipsis.svg';
import useLocalStorageReview from '../../../hooks/useLocalStorageReview';
import usePerfModal from '../../../hooks/usePerfModal';
import {
  selectActiveTab,
  selectIsEditRatingSummary,
  setActiveTab,
} from '@@/redux/slice/revieweeSlice';
import {
  getMarkReviewAsReadAsync,
  reviewState,
  selectIsEditReviewHistoryDetail,
  selectShowCurrentSelfReviewInput,
  selectShowDoneSelfReviewInput,
  setCurrentSelfReviewText,
  setDoneSelfReviewText,
  setIsEditReviewHistoryDetail,
  setShowCurrentSelfReviewInput,
  setShowDoneSelfReviewInput,
  setUpdateUnreadStatus,
} from '@@/redux/slice/reviewSlice';
import { TABS_KEY } from '../../constant/reviewee';
import ChangeHistory from '../ChangeHistory';
import EditCircleButton from '../EditCircleButton';
import perfMessage from '../PerfMessage/perfMessage';
import perfModalConfirm from '../PerfModalConfirm';
import RatingSummaryEditor from '../RatingSummaryEditor';
import RevieweeReviewEditor from '../RevieweeReviewEditor';
import RevieweeReviewPreview from '../RevieweeReviewEditor/preview';
import SendEditRequestModal from '../SendEditRequestModal';
import SelfReviewInput from './SelfReviewInput';
import SelfReviewPreview from './SelfReviewPreview';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { getRequestToEditStatus, goToCycleFormPage } from '@@/_new_src_/utils/feature/cycle';
import {
  cycleFeedbacks,
  myCycles,
  setCancelStatus,
  setIsOperationCycle,
} from '@@/_new_src_/store/myCyclesSlice';
import CycleStatusBar from '@@/_new_src_/page/myCycles/component/CycleStatusBar';
import { dashboard } from '@@/redux/slice/dashboardSlice';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import { MY_CYCLE_LIST_API } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { sendRequestData } from '@@/_new_src_/store/cycleDetailSlice';
import V2SendRequest from '@@/_new_src_/page/cycleDetail/component/SendRequest';
import FeedbackPreviewDetail from '@@/_new_src_/page/myCycles/component/FeedbackPreviewDetail';
import NoFeedbackContainer from '@@/_new_src_/page/cycleDetail/component/NoFeedbackContainer';
import NoDoneFeedbackContainer from '@@/_new_src_/page/cycleDetail/component/NoDoneFeedbackContainer';
import { getCycleFeedbacksAsync } from '@@/_new_src_/store/myCyclesSlice/asyncThunk';
import TabPaneComponent from '@@/_new_src_/components/TabPaneComponent';
import { PPList, userInfo } from '@@/_new_src_/store/userSlice';
import RequestToEditTag from '@@/_new_src_/features/RequestToEditTag';
import { nonEngagedCycles } from '@@/_new_src_/store/nonEngagedCyclesSlice';
import CycleStatusBadge from '@@/_new_src_/features/CycleStatusBadge';
import commonLocale from '@@/_new_src_/local/common/en_US';
import { CURRENT_PAGE, CYCLE_PAGE_LINK, MENU_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { onEnter } from '@@/_new_src_/utils';
import './index.less';
import Tip from '@@/_new_src_/components/Tip';
import {
  AUTO_PUBLISH_CALIBRATING_RESULT_DATE,
  CALIBRATION_COMMUNICATION_DURATION,
} from '@@/_new_src_/constants/common';

export const DEFAULT_OPERATION_CONFIG = {
  ratingSummaryEditButton: true,
  requestEditButton: true,
  addNotesButton: true,
  editNotesButton: true,
  notesRecordTips: true,
};

const ReviewDetailPreview = props => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { trackEvent } = useMatomo();
  const localeResource = useSelector(selectLocaleResource);

  const {
    reviewList: locale = {},
    feedback: {
      requestTab: { pendingOrCancelledFeedback, collectMoreFeedback },
    },
    reviewees: { reviewInputTab: reviewInputTabLocale, feedbackTab: feedbackTabLocale },
    myCycles: { calibratingTip: calibratingTipLocale, calibrationCommunicationTip },
  } = localeResource || {};

  const {
    ratingSummaryEditable,
    defaultExpand,
    showChangeHistory,
    changeHistoryList,
    operation: originOperation,
    extra,
    onMarkReviewAsReadSuccess,
    onCancelReviewSuccess,
    onCheckInNotesModalSuccess,
    reviewInputTab,
    needActivatedTab,
    getCurrentReviewList,
    getCurrentReviewDetail,
    isHistoryDetail,
    showFeedbackTabThoughNoSubmittedFeedback,
    isCurrentReviewDetail,
    isSubmitModalVisible,
    isRevieweeReviewDetail,
  } = props;
  const [isExpand, setIsExpand] = useState(() => !!defaultExpand && true);
  const [requestEditModalVisible, onOpenRequestEditModal, onCloseRequestEditModal] = usePerfModal();
  const [isEditDoneReviewByReviewer, setIsEditDoneReviewByReviewer] = useState(false);
  const [isEditDoneReviewByReviewee, setIsEditDoneReviewByReviewee] = useState(false);
  const activeTab = useSelector(selectActiveTab);
  const showCurrentSelfReviewInput = useSelector(selectShowCurrentSelfReviewInput);
  const showDoneSelfReviewInput = useSelector(selectShowDoneSelfReviewInput);
  const [isChangeHistoryExpand, setIsChangeHistoryExpand] = useState(false);
  const { status: requestToEditStatus } = useSelector(sendRequestData);
  const { isApprovedRequestToEdit } = getRequestToEditStatus(requestToEditStatus);
  const { success: isGetCycleFeedbackSuccess, list: cycleFeedbackList } =
    useSelector(cycleFeedbacks);
  const { isEditByTbp } = useSelector(nonEngagedCycles);
  const isEditRatingSummary = useSelector(selectIsEditRatingSummary);

  const { isNonEngagedCyclesPage } = CURRENT_PAGE(location.pathname);

  const { dashboardCycleDetail } = useSelector(dashboard);
  const {
    cyclesCycleDetailV1,
    updateUnreadStatus: { loading: markReviewAsReadLoading, success: markReviewAsReadSuccess },
  } = useSelector(reviewState);

  const [editV1CycleDetailData, setEditV1CycleDetailData] = useState({});

  useEffect(() => {
    if (!isEmpty(dashboardCycleDetail.data)) {
      setEditV1CycleDetailData(dashboardCycleDetail.data);
    }
    if (!isEmpty(cyclesCycleDetailV1.data)) {
      setEditV1CycleDetailData(cyclesCycleDetailV1.data);
    }
  }, [dashboardCycleDetail.data, cyclesCycleDetailV1.data]);

  const {
    currentCycleDetail: {
      id: cycleId,
      status = '',
      assessment = {},
      additionalPartnerAssessments = [],
      selfAssessment = {},
      isNoneEngaged,
      canRequestToEdit,
      canEdit,
      feedbacks = [],
      isOpenCycle,
      isNewCycle,
      isCancelledCycle,
      isUnreadCycle,
      isDoneCycle,
      isOpenOrDraftCycle,
      isOverdueCycle,
      isPerformancePartner,
      isTwer,
      assessmentNonEngaged,
      isProbation,
      cycleDetailLoading,
      isCalibratingCycle,
      isFirstHalfOfYear,
      isInCommunicationStage,
    },
  } = useSelector(cycleDetailByVersion);
  const { loading: isGetUserInfoLoading } = useSelector(PPList);
  const { loading: isGetFeedbackLoading } = useSelector(cycleFeedbacks);

  const [isSelfPreviewExpand, setIsSelfPreviewExpand] = useState(false);
  const selfReviewInputRef = useRef(null);
  const { removeLocalReview } = useLocalStorageReview();
  const isEditReviewHistoryDetail = useSelector(selectIsEditReviewHistoryDetail);
  const { id: cycleIdByPathname } = useParams();
  const [selfReviewAttachment, setSelfReviewAttachment] = useState(null);
  const hasSubmitSelfReview = !!(
    get(selfAssessment, 'content', '') || get(selfAssessment, 'attachmentName', '')
  );
  const { cycleVersion } = useSelector(myCycles);
  const { hasOverdueCycle } = useSelector(userInfo);

  useEffect(() => {
    dispatch(setShowCurrentSelfReviewInput(false));
  }, [location.pathname]);

  useMount(() => {
    const { activeTab: activeKey } = queryString.parse(location.search);
    dispatch(setActiveTab(activeKey || TABS_KEY.REVIEW_DETAIL));
  });

  const isMoreFeedbackHintVisible = useMemo(() => {
    const { pathname } = location;
    return pathname.endsWith('cycles') && (isOpenOrDraftCycle || isOverdueCycle);
  }, [location, status]);

  const gotoFeedbackPage = () => {
    history.push(MENU_PAGE_LINK.FEEDBACK_MANAGEMENT_PAGE);
  };

  const showSelfReviewInput = useMemo(() => {
    return isEditDoneReviewByReviewee
      ? showDoneSelfReviewInput
      : showCurrentSelfReviewInput && (isCurrentReviewDetail || isOverdueCycle);
  }, [
    isCurrentReviewDetail,
    isEditDoneReviewByReviewee,
    showCurrentSelfReviewInput,
    showDoneSelfReviewInput,
  ]);

  const setShowSelfReviewInput = useCallback(
    value => {
      if (isEditDoneReviewByReviewee) {
        dispatch(setShowDoneSelfReviewInput(value));
        return;
      }
      dispatch(setShowCurrentSelfReviewInput(value));
    },
    [dispatch, isEditDoneReviewByReviewee],
  );

  const operation = useMemo(
    () => originOperation && { ...DEFAULT_OPERATION_CONFIG, ...originOperation },
    [originOperation],
  );

  const onEditReview = useCallback(() => {
    const redirect = () => {
      removeLocalReview();
      const editCyclePageUrl = goToCycleFormPage({ cycleVersion, id: cycleId });
      history.push(editCyclePageUrl);
    };
    if (selfReviewInputRef.current) {
      const { cancelInput } = selfReviewInputRef.current;
      cancelInput(redirect);
    } else {
      redirect();
    }
  }, [removeLocalReview, isNoneEngaged, isOpenOrDraftCycle, history, cycleVersion]);

  const onEditDoneReview = useCallback(async () => {
    dispatch(setActiveTab(TABS_KEY.REVIEW_DETAIL));
    removeLocalReview();
    if (isTwer) {
      setIsEditDoneReviewByReviewee(true);
      dispatch(setShowDoneSelfReviewInput(true));
      dispatch(setIsEditReviewHistoryDetail(true));
    } else {
      setIsEditDoneReviewByReviewer(true);
      if (props.onEditRatingSummary) {
        props.onEditRatingSummary();
      }
    }
    setIsExpand(true);
  }, [removeLocalReview, isTwer, dispatch, props]);

  useEffect(() => {
    if (isEditReviewHistoryDetail && isHistoryDetail) {
      setIsEditDoneReviewByReviewee(true);
      dispatch(setShowDoneSelfReviewInput(true));
      setIsExpand(true);
    }
  }, [dispatch, isEditReviewHistoryDetail, isHistoryDetail]);

  const onCancelReview = useCallback(() => {
    const {
      reviewList: { confirm },
    } = localeResource;
    const closeModal = perfModalConfirm({
      className: 'confirm-cancel-preview-modal',
      cancelText: confirm.cancel,
      okText: confirm.ok,
      centered: true,
      content: isNoneEngaged ? confirm.nonEngagedContent : confirm.content,
      onOk: async () => {
        trackEvent({
          category: MY_CYCLE_LIST_API.category,
          action: MY_CYCLE_LIST_API.action.TWER_CANCELS_V1_CYCLE,
        });
        await cancelReview(cycleId);
        dispatch(setCancelStatus({ success: true, loading: false }));
        closeModal();
        perfMessage.success(localeResource.reviewList.cancelSuccessMessage);
        dispatch(setIsEditReviewHistoryDetail(false));
        setShowSelfReviewInput(false);
        if (onCancelReviewSuccess) {
          onCancelReviewSuccess();
        }
      },
    });
  }, [
    localeResource,
    isNoneEngaged,
    dispatch,
    setShowSelfReviewInput,
    onCancelReviewSuccess,
    cycleId,
  ]);

  const onMarkReviewAsRead = useCallback(() => {
    dispatch(getMarkReviewAsReadAsync(cycleId));
  }, [cycleId]);

  useEffect(() => {
    cycleId && dispatch(getCycleFeedbacksAsync(cycleId));
  }, [cycleId]);

  useEffect(() => {
    if (onMarkReviewAsReadSuccess && markReviewAsReadSuccess) {
      onMarkReviewAsReadSuccess();
    }
  }, [markReviewAsReadSuccess, onMarkReviewAsReadSuccess]);

  useEffect(() => {
    if (markReviewAsReadSuccess) {
      dispatch(setUpdateUnreadStatus({ loading: false, success: false }));
      window.open(CYCLE_PAGE_LINK.MY_CYCLES, '_self');
    }
  }, [markReviewAsReadSuccess]);

  const showRequestEditButton = useMemo(() => {
    return (
      isTwer && isDoneCycle && cycleVersion === CYCLE_VERSION.OLD && operation.requestEditButton
    );
  }, [isTwer, isDoneCycle, cycleVersion, operation.requestEditButton]);

  const isShowReviewerRequestEditButton = useMemo(
    () =>
      operation.requestEditButton &&
      isPerformancePartner &&
      !isEditDoneReviewByReviewer &&
      isDoneCycle &&
      cycleVersion === CYCLE_VERSION.OLD,
    [
      isDoneCycle,
      isEditDoneReviewByReviewer,
      isPerformancePartner,
      operation.requestEditButton,
      cycleVersion,
    ],
  );
  const renderDoneReviewEditOperation = useCallback(() => {
    return (
      <div className="review-detail-preview-title-extra">
        {canEdit ? (
          <>
            {!isEditDoneReviewByReviewee && !isEditDoneReviewByReviewer && !isCalibratingCycle && (
              <EditCircleButton onClick={onEditDoneReview} />
            )}
          </>
        ) : (
          <>
            {showRequestEditButton && (
              <RequestToEditTag
                canEdit={canEdit}
                canRequestToEdit={canRequestToEdit}
                isDoneCycle={true}
                onClick={onOpenRequestEditModal}
              />
            )}
          </>
        )}
      </div>
    );
  }, [
    isEditDoneReviewByReviewee,
    canEdit,
    canRequestToEdit,
    isEditDoneReviewByReviewer,
    onEditDoneReview,
    showRequestEditButton,
    onOpenRequestEditModal,
    isCalibratingCycle,
  ]);

  const operationDropdownMenu = useCallback(
    () => (
      <Menu>
        <Menu.Item className="edit-menu" onClick={onEditReview}>
          <img src={EditBlueIcon} className="edit-icon" alt="" />
          {locale.editText}
        </Menu.Item>
        <Menu.Divider className="menu-divider" />
        <Menu.Item
          className={cls('cancel-menu', {
            'cancel-menu-non-engaged': isNoneEngaged || isOverdueCycle,
            'cancel-menu-probation': isProbation,
          })}
          onClick={onCancelReview}
          disabled={isNoneEngaged || isOverdueCycle || isProbation}
        >
          <img
            src={isNoneEngaged || isOverdueCycle || isProbation ? DeleteGreyIcon : DeleteRedIcon}
            className="cancel-icon"
            alt=""
          />
          {locale.cancelText}
        </Menu.Item>
      </Menu>
    ),
    [
      isNoneEngaged,
      locale.cancelText,
      locale.editText,
      onCancelReview,
      onEditReview,
      isOverdueCycle,
      isProbation,
    ],
  );

  const renderOpenReviewOperation = useCallback(() => {
    return (
      <div className="review-detail-preview-title-extra" tabIndex={0}>
        <Dropdown
          dropdownRender={operationDropdownMenu}
          trigger={['click']}
          placement="bottomRight"
          overlayClassName={cls('operation-dropdown-menu', {
            'operation-dropdown-menu-overdue-cycles':
              location.pathname === CYCLE_PAGE_LINK.MY_CYCLES && hasOverdueCycle,
            'operation-dropdown-menu-overdue-history':
              location.pathname.includes(CYCLE_PAGE_LINK.CYCLE_HISTORY_DETAIL) && hasOverdueCycle,
          })}
        >
          <img src={EllipsisIcon} className="ellipsis-button" alt="" />
        </Dropdown>
      </div>
    );
  }, [operationDropdownMenu]);

  const renderCustomHeaderOperation = useCallback(() => {
    return (
      isValidElement(operation.header) && (
        <div className="review-detail-preview-title-extra">{operation.header}</div>
      )
    );
  }, [operation.header]);

  const onToggleChangeHistoryExpand = useCallback(() => {
    if (activeTab !== TABS_KEY.REVIEW_DETAIL) {
      setIsChangeHistoryExpand(true);
      dispatch(setActiveTab(TABS_KEY.REVIEW_DETAIL));
    } else {
      setIsChangeHistoryExpand(!isChangeHistoryExpand);
    }
  }, [isChangeHistoryExpand, activeTab, dispatch]);

  const renderChangeHistoryOperation = useCallback(
    () => (
      <div className="review-detail-preview-title-extra">
        <img
          alt=""
          src={ChangeHistoryIcon}
          className="change-history-icon"
          onClick={onToggleChangeHistoryExpand}
        />
      </div>
    ),
    [onToggleChangeHistoryExpand],
  );

  const isShowOpenReviewOperation = useMemo(() => {
    let mustCondition = operation && activeTab === TABS_KEY.REVIEW_DETAIL;
    let isShow = mustCondition && (isOpenCycle || isNewCycle) && isTwer;

    if (cycleVersion === CYCLE_VERSION.NEW) {
      let isShowForNewCycle = mustCondition && (isNewCycle || isOpenOrDraftCycle || isOverdueCycle);

      isShow = isShowForNewCycle && isTwer;
    }
    if (isNonEngagedCyclesPage) {
      dispatch(setIsOperationCycle(false));
    } else {
      dispatch(setIsOperationCycle(isShow));
    }
    return isShow;
  }, [isOpenCycle, isTwer, activeTab, isOpenOrDraftCycle, isNonEngagedCyclesPage]);

  const renderHeaderOperation = useCallback(() => {
    if (!operation) {
      return null;
    }

    const isShowDoneReviewEditOperation =
      isDoneCycle && isTwer && activeTab === TABS_KEY.REVIEW_DETAIL;

    if (operation.header) {
      return renderCustomHeaderOperation();
    }
    if (showChangeHistory) {
      return renderChangeHistoryOperation();
    }
    if (isShowDoneReviewEditOperation) {
      return renderDoneReviewEditOperation();
    }
    if (isShowOpenReviewOperation) {
      return renderOpenReviewOperation();
    }
    return null;
  }, [
    operation,
    isDoneCycle,
    isOpenCycle,
    isTwer,
    activeTab,
    showChangeHistory,
    renderCustomHeaderOperation,
    renderDoneReviewEditOperation,
    renderOpenReviewOperation,
    renderChangeHistoryOperation,
  ]);

  const isPageLoading = useMemo(() => {
    return cycleDetailLoading || isGetUserInfoLoading || isGetFeedbackLoading;
  }, [cycleDetailLoading, isGetUserInfoLoading, isGetFeedbackLoading]);

  const showRatingSummary = useMemo(() => {
    // not show rating when is china region contributor
    if (!assessment) {
      return false;
    }

    if (isNil(isCancelledCycle) || isNil(isOpenCycle) || isNil(isDoneCycle)) {
      return false;
    }

    return (
      !isPageLoading &&
      (!isTwer ? !isCancelledCycle && (!isOpenCycle || ratingSummaryEditable) : isDoneCycle)
    );
  }, [
    assessment,
    isTwer,
    isCancelledCycle,
    isOpenCycle,
    ratingSummaryEditable,
    isDoneCycle,
    isPageLoading,
  ]);

  const onSendEditRequestSuccess = useCallback(() => {
    onCloseRequestEditModal();
    if (props.onSendEditRequestSuccess) {
      props.onSendEditRequestSuccess();
    }
  }, [onCloseRequestEditModal, props]);

  const onSendEditRequestCancel = useCallback(() => {
    onCloseRequestEditModal();
  }, [onCloseRequestEditModal, props]);

  const onRevieweeReviewEditSuccess = useCallback(() => {
    dispatch(setIsEditReviewHistoryDetail(false));
    dispatch(setDoneSelfReviewText());
    removeLocalReview();
    setShowSelfReviewInput(false);
    setIsEditDoneReviewByReviewee(false);
    setIsSelfPreviewExpand(true);
    if (props.onRevieweeReviewEditSuccess) {
      props.onRevieweeReviewEditSuccess();
    }
  }, [dispatch, removeLocalReview, setShowSelfReviewInput, props]);

  const onRevieweeReviewEditCancel = useCallback(
    hasShowModal => {
      const clearValue = () => {
        setShowSelfReviewInput(false);
        dispatch(setDoneSelfReviewText());
        removeLocalReview();
        setIsEditDoneReviewByReviewee(false);
        dispatch(setIsEditReviewHistoryDetail(false));
      };
      if (hasShowModal) {
        clearValue();
        if (props.onRevieweeReviewEditCancel) {
          props.onRevieweeReviewEditCancel();
        }
      } else if (selfReviewInputRef.current) {
        const { cancelInput } = selfReviewInputRef.current;
        cancelInput(() => {
          clearValue();
        });
      }
    },
    [setShowSelfReviewInput, dispatch, removeLocalReview, props],
  );

  const onSaveRatingSummary = useCallback(() => {
    setIsEditDoneReviewByReviewer(false);
    if (props.onSaveRatingSummary) {
      props.onSaveRatingSummary();
    }
  }, [props]);

  const onCancelEditRatingSummary = useCallback(() => {
    setIsEditDoneReviewByReviewer(false);
    if (props.onCancelEditRatingSummary) {
      props.onCancelEditRatingSummary();
    }
  }, [props]);

  const openSelfReview = useCallback(() => {
    dispatch(setCurrentSelfReviewText());
    setShowSelfReviewInput(true);
  }, [dispatch, setShowSelfReviewInput]);

  const closeSelfReviewInput = useCallback(() => {
    dispatch(setCurrentSelfReviewText());
    setShowSelfReviewInput(false);
  }, [dispatch, setShowSelfReviewInput]);

  const onSubmitSelfReviewInput = useCallback(() => {
    dispatch(setCurrentSelfReviewText());
    setShowSelfReviewInput(false);
    setIsSelfPreviewExpand(true);
    if (getCurrentReviewList) {
      getCurrentReviewList();
    }
    if (getCurrentReviewDetail) {
      getCurrentReviewDetail();
    }
  }, [dispatch, setShowSelfReviewInput, getCurrentReviewList, getCurrentReviewDetail]);

  const isShowReviewerEditDoneReviewButton = useMemo(
    () =>
      canEdit &&
      isPerformancePartner &&
      !isEditDoneReviewByReviewer &&
      cycleVersion === CYCLE_VERSION.OLD,
    [isEditDoneReviewByReviewer, isPerformancePartner, canEdit, cycleVersion],
  );

  const isEditDoneCycleAndIsTwer = useMemo(() => {
    return isApprovedRequestToEdit && isTwer;
  }, [isApprovedRequestToEdit, isTwer]);

  const onEditRatingSummary = useCallback(() => {
    if (isDoneCycle) {
      setIsEditDoneReviewByReviewer(true);
    }
    if (props.onEditRatingSummary) {
      props.onEditRatingSummary();
    }
  }, [isDoneCycle, props]);

  const isShowV1RatingSummaryEditable = useMemo(() => {
    return (
      cycleVersion === CYCLE_VERSION.OLD &&
      ((isPerformancePartner && ratingSummaryEditable) || isEditDoneReviewByReviewer)
    );
  }, [cycleVersion, isPerformancePartner, ratingSummaryEditable, isEditDoneReviewByReviewer]);

  const isShowV2RatingSummaryEditable = useMemo(() => {
    return (
      cycleVersion === CYCLE_VERSION.NEW &&
      isPerformancePartner &&
      (isApprovedRequestToEdit || ratingSummaryEditable)
    );
  }, [cycleVersion, isPerformancePartner, isApprovedRequestToEdit, ratingSummaryEditable, canEdit]);

  const isEditableWhenUnmark = useMemo(() => {
    return isNonEngagedCyclesPage && isEditByTbp && assessmentNonEngaged && isEditRatingSummary;
  }, [isEditByTbp, assessmentNonEngaged, isEditRatingSummary, location.pathname]);

  useEffect(() => {
    if (needActivatedTab) {
      setActiveTab(needActivatedTab);
    }
  }, [needActivatedTab, ratingSummaryEditable, dispatch]);

  const jumpToFeedbackRequestToClientsModal = useCallback(() => {
    history.replace(MENU_PAGE_LINK.FEEDBACK_MANAGEMENT_PAGE, { showRequestToClientsModal: true });
  }, [history]);

  const jumpToSendRequestToColleaguesModal = useCallback(() => {
    history.replace(MENU_PAGE_LINK.FEEDBACK_MANAGEMENT_PAGE, {
      showRequestToColleaguesModal: true,
    });
  }, [history]);

  const renderCalibratingFeedbackHint = useCallback(() => {
    return (
      isCalibratingCycle && (
        <div className={'calibrating-feedback-hint'}>
          <i className="ri-information-fill"></i>
          <div className={'calibrating-feedback-hint-text'}>
            You can't collect more feedback for cycles that are in performance calibration.
          </div>
        </div>
      )
    );
  }, [isCalibratingCycle]);

  const renderFeedbackRequestTabContent = useCallback(
    clientOrSubmittedColleagueFeedback => {
      return (
        <div className="feedback-list">
          {isMoreFeedbackHintVisible && (
            <div className="more-feedback-hint">
              <a
                tabIndex={0}
                onKeyDown={e => {
                  onEnter(e, gotoFeedbackPage);
                }}
                onClick={gotoFeedbackPage}
              >
                <i className="ri-send-plane-line" />
                {collectMoreFeedback.hint}
              </a>
              &nbsp;
              <span> {collectMoreFeedback.additionalText}</span>
            </div>
          )}
          {renderCalibratingFeedbackHint()}
          {clientOrSubmittedColleagueFeedback.map(item => (
            <FeedbackPreviewDetail key={item.id} feedback={item} />
          ))}
        </div>
      );
    },
    [isOverdueCycle],
  );

  const renderNoFeedbackRequestTabContent = useCallback(() => {
    return (
      <div className="empty-feedback">
        <NoFeedbackContainer />
      </div>
    );
  }, [jumpToFeedbackRequestToClientsModal, jumpToSendRequestToColleaguesModal, isOverdueCycle]);

  const handleViewFeedbackRequest = useCallback(() => {
    history.push(MENU_PAGE_LINK.FEEDBACK_MANAGEMENT_PAGE);
  }, [history]);

  const renderPendingOrCancelledFeedbackTabContent = useCallback(
    () => <NoDoneFeedbackContainer />,
    [pendingOrCancelledFeedback, handleViewFeedbackRequest],
  );

  const {
    tag: { nonEngagedCycleByTBPTag },
  } = commonLocale;
  const detailPreviewTitleRender = () => {
    const oldStatusTitleRender = () => {
      return (
        <>
          <div className="review-detail-preview-title-flag">
            <div
              className={cls('review-detail-preview-title-label', {
                [`review-detail-preview-title-${status && status.toLowerCase()}`]: status,
              })}
            >
              <CycleStatusBadge status={isOverdueCycle ? CYCLE_STATUS_ENUM.OVERDUE : status} />
            </div>
            {isNoneEngaged && (
              <div className="review-detail-preview-title-non-Engaged-Cycle">
                {nonEngagedCycleByTBPTag}
              </div>
            )}
          </div>
          {renderHeaderOperation()}
        </>
      );
    };
    return (
      <div className={cls('review-detail-preview-title')}>
        {cycleVersion === CYCLE_VERSION.NEW ? <CycleStatusBar /> : oldStatusTitleRender()}
      </div>
    );
  };

  const renderOrangeTip = useMemo(() => {
    if (isCalibratingCycle && isTwer) {
      let communicationDuration = isFirstHalfOfYear
        ? {
            ...CALIBRATION_COMMUNICATION_DURATION.H1,
            publishTime: AUTO_PUBLISH_CALIBRATING_RESULT_DATE.H1,
          }
        : {
            ...CALIBRATION_COMMUNICATION_DURATION.H2,
            publishTime: AUTO_PUBLISH_CALIBRATING_RESULT_DATE.H2,
          };
      return (
        <Tip
          wording={
            isInCommunicationStage
              ? calibrationCommunicationTip
              : calibratingTipLocale({ ...communicationDuration })
          }
          className={'orange-tip'}
        />
      );
    }
    return null;
  }, [isCalibratingCycle, isTwer, isFirstHalfOfYear, isInCommunicationStage]);

  const renderReviewDetailTabContent = () => (
    <div>
      {renderOrangeTip}
      <V2SendRequest />
      {showChangeHistory && isChangeHistoryExpand && <ChangeHistory list={changeHistoryList} />}
      {extra}
      {showRatingSummary && (
        <RatingSummaryEditor
          showEditIcon={operation?.ratingSummaryEditButton}
          isShowRequestEditButton={isShowReviewerRequestEditButton}
          isShowDoneReviewEditButton={isShowReviewerEditDoneReviewButton}
          onSave={onSaveRatingSummary}
          onCancel={onCancelEditRatingSummary}
          editable={
            isShowV1RatingSummaryEditable || isShowV2RatingSummaryEditable || isEditableWhenUnmark
          }
          isExpand={isExpand}
          onEdit={onEditRatingSummary}
          onOpenRequestEditModal={onOpenRequestEditModal}
          isSubmitModalVisible={isSubmitModalVisible}
        />
      )}
      {isHistoryDetail && isUnreadCycle && (
        <div className="confirm-read-and-acknowledge-container">
          <div className="read-and-acknowledge-content">
            <span className="read-and-acknowledge-title">{locale.whatNow}&emsp;</span>
            <span>{locale.confirmAssessmentResult}</span>
          </div>
          <div>
            <Button
              onClick={onMarkReviewAsRead}
              loading={markReviewAsReadLoading}
              disabled={markReviewAsReadLoading}
              className="mark-review-as-read-button"
            >
              <i className="ri-book-read-line book-read-icon" />
              {locale.readAndAcknowledged}
            </Button>
          </div>
        </div>
      )}
      {(showSelfReviewInput || isEditDoneCycleAndIsTwer) && (
        <SelfReviewInput
          onCancelSelfReviewInput={closeSelfReviewInput}
          onSubmitSelfReviewInput={onSubmitSelfReviewInput}
          isEditDoneReview={isEditDoneReviewByReviewee}
          hasSubmitSelfReview={hasSubmitSelfReview}
          ref={selfReviewInputRef}
          selfReviewAttachment={selfReviewAttachment}
          setSelfReviewAttachment={setSelfReviewAttachment}
        />
      )}
      {hasSubmitSelfReview &&
        !showSelfReviewInput &&
        !isEditDoneReviewByReviewee &&
        !isEditDoneCycleAndIsTwer && (
          <SelfReviewPreview
            showEditButton={isOpenCycle && isTwer && !isNonEngagedCyclesPage}
            onClickEditSelfReview={openSelfReview}
            isExpand={isSelfPreviewExpand}
            setIsExpand={setIsSelfPreviewExpand}
          />
        )}
      {isEditDoneReviewByReviewee ? (
        <RevieweeReviewEditor
          review={editV1CycleDetailData}
          updatedMode
          onEditSuccess={onRevieweeReviewEditSuccess}
          onEditCancel={onRevieweeReviewEditCancel}
          hasSubmitSelfReview={hasSubmitSelfReview}
          isEditDoneReviewByReviewee={isEditDoneReviewByReviewee}
          selfReviewAttachment={selfReviewAttachment}
        />
      ) : (
        <RevieweeReviewPreview
          locale={locale}
          isExpand={isExpand}
          operation={operation}
          onCheckInNotesModalSuccess={onCheckInNotesModalSuccess}
          showCreateSelfReview={
            !hasSubmitSelfReview && isOpenCycle && isTwer && !showSelfReviewInput
          }
          onClickCreateSelfReview={openSelfReview}
          isCurrentReviewDetail={isCurrentReviewDetail}
          isNonEngagedReview={isNoneEngaged}
        />
      )}
      {isUnreadCycle && isCurrentReviewDetail && (
        <div className="review-detail-preview-footer">
          <div className="read-and-acknowledge-content">
            <span className="read-and-acknowledge-title">{locale.whatNow}&emsp;</span>
            <span>{locale.confirmAssessmentResult}</span>
          </div>
          <Button
            onClick={onMarkReviewAsRead}
            loading={markReviewAsReadLoading}
            disabled={markReviewAsReadLoading}
            className="mark-review-as-read-button"
          >
            <i className="ri-book-read-line book-read-icon" />
            {locale.readAndAcknowledged}
          </Button>
        </div>
      )}
      {isDoneCycle && (
        <SendEditRequestModal
          visible={requestEditModalVisible}
          onCancel={onSendEditRequestCancel}
          onSuccess={onSendEditRequestSuccess}
        />
      )}
    </div>
  );

  const total = useMemo(
    () => additionalPartnerAssessments?.length || 0,
    [additionalPartnerAssessments],
  );

  const renderReviewInputTabContent = useCallback(() => {
    return reviewInputTab && <div>{reviewInputTab}</div>;
  }, [localeResource, reviewInputTab]);

  const renderFeedbackTabContent = useCallback(() => {
    const clientOrSubmittedColleagueFeedback = feedbacks.filter(
      feedback => feedback.status === 'Submitted' || feedback.status === '',
    );

    const clientOrSubmittedColleagueFeedbackCount = clientOrSubmittedColleagueFeedback.length;
    const pendingOrCancelledFeedbackCount = feedbacks.filter(
      feedback => feedback.status === 'Pending' || feedback.status === 'Cancelled',
    ).length;

    if (!clientOrSubmittedColleagueFeedbackCount && !showFeedbackTabThoughNoSubmittedFeedback) {
      return null;
    }

    if ((isUnreadCycle && isEmpty(feedbacks)) || !isGetCycleFeedbackSuccess) {
      return null;
    }
    return (
      <div>
        {clientOrSubmittedColleagueFeedbackCount !== 0 &&
          renderFeedbackRequestTabContent(cycleFeedbackList)}
        {showFeedbackTabThoughNoSubmittedFeedback &&
          clientOrSubmittedColleagueFeedbackCount === 0 &&
          pendingOrCancelledFeedbackCount === 0 &&
          renderNoFeedbackRequestTabContent()}
        {showFeedbackTabThoughNoSubmittedFeedback &&
          clientOrSubmittedColleagueFeedbackCount === 0 &&
          pendingOrCancelledFeedbackCount !== 0 &&
          renderPendingOrCancelledFeedbackTabContent()}
      </div>
    );
  }, [
    localeResource,
    feedbacks,
    isGetCycleFeedbackSuccess,
    cycleFeedbackList,
    renderFeedbackRequestTabContent,
    renderNoFeedbackRequestTabContent,
    renderPendingOrCancelledFeedbackTabContent,
    showFeedbackTabThoughNoSubmittedFeedback,
    isUnreadCycle,
  ]);

  const tabPaneTabList = useMemo(() => {
    const feedbackLength = cycleFeedbackList.length;
    const tabPane = {
      tabList: [],
      children: [],
      keys: [],
    };
    if (reviewInputTab) {
      tabPane.tabList = [locale.reviewDetailTabTitle, reviewInputTabLocale.tabTitle(total)];
      tabPane.children = [renderReviewDetailTabContent(), renderReviewInputTabContent()];
      tabPane.keys = [TABS_KEY.REVIEW_DETAIL, TABS_KEY.REVIEW_INPUT];
    } else {
      tabPane.tabList = [locale.reviewDetailTabTitle];
      tabPane.children = [renderReviewDetailTabContent()];
      tabPane.keys = [TABS_KEY.REVIEW_DETAIL];
    }

    if (feedbackLength > 0 || (isTwer && isOpenCycle && !isNonEngagedCyclesPage)) {
      tabPane.tabList.push(feedbackTabLocale.tabTitle(feedbackLength));
      tabPane.children.push(renderFeedbackTabContent());
      tabPane.keys.push(TABS_KEY.FEEDBACK);
    }
    return tabPane;
  }, [
    reviewInputTab,
    cycleFeedbackList,
    total,
    isShowV1RatingSummaryEditable,
    isShowV2RatingSummaryEditable,
    renderReviewDetailTabContent,
    renderReviewInputTabContent,
    renderFeedbackTabContent,
    isTwer,
    isOpenCycle,
    showSelfReviewInput,
    isNonEngagedCyclesPage,
  ]);

  const currentActiveTab = useMemo(() => {
    const currentActiveTab =
      queryString.parse(location.search)?.activeTab || activeTab || TABS_KEY.REVIEW_DETAIL;
    return tabPaneTabList.keys.indexOf(currentActiveTab).toString();
  }, [activeTab, tabPaneTabList.keys, location.search]);

  const onTabsChange = useCallback(
    activeKey => {
      const tabValue = tabPaneTabList.keys[activeKey] || TABS_KEY.REVIEW_DETAIL;
      if (isRevieweeReviewDetail) {
        history.replace(`/performance/twerisupport/${cycleIdByPathname}?activeTab=${tabValue}`);
      }
      dispatch(setActiveTab(tabValue));
    },
    [dispatch, history, isRevieweeReviewDetail, cycleIdByPathname, tabPaneTabList.keys],
  );

  return (
    <div
      className={cls('review-detail-preview', { 'review-detail-preview-expand': isExpand })}
      key={cycleId}
    >
      {detailPreviewTitleRender()}
      <TabPaneComponent
        tabList={tabPaneTabList.tabList}
        children={tabPaneTabList.children}
        size={'large'}
        centered={true}
        tabBarGutter={9}
        className="review-tabs-container"
        updateActiveKey={onTabsChange}
        activeKey={currentActiveTab}
      />
    </div>
  );
};

ReviewDetailPreview.defaultProps = {
  showChangeHistory: false,
  changeHistoryList: [],
  reviewInputEditorProps: {
    onEditReviewInput: () => {},
    recentSubmittedReviewInputId: '',
  },
  ratingSummaryEditable: false,
  operation: DEFAULT_OPERATION_CONFIG,
};

export default memo(ReviewDetailPreview);
