import { isEmpty, isNil, isNull } from 'lodash';
import {
  DEFAULT_TABLE_COLUMN_SORT_CONFIG,
  SORT_DIRECTION_FOR_FE,
} from '@@/_new_src_/constants/table';

export const getImageInfo = (
  src: string,
  callback: (imgInfo: { width: number; height: number }) => void,
) => {
  const img = new Image();
  img.src = src;
  if (img.complete) {
    callback({
      width: img.width || 0,
      height: img.height || 0,
    });
  } else {
    img.onload = () => {
      callback({
        width: img.width || 0,
        height: img.height || 0,
      });
    };
  }
};

export const getImageRealSize = (
  parentWidth: number,
  parentHeight: number,
  imgWidth: number,
  imgHeight: number,
  radio?: number,
) => {
  const curRadio = radio || 1;
  const real = { modal: { width: 0, height: 0 }, img: { width: '' } };
  const scaleC = parentWidth / parentHeight;
  const scaleI = imgWidth / imgHeight;
  if (imgWidth < parentWidth && imgHeight < parentHeight) {
    const curImgWidth = imgWidth.toString() + 'px';
    real.modal.width = imgWidth;
    real.modal.height = imgHeight;
    real.img.width = curImgWidth;
    return real;
  }
  if (scaleC > scaleI) {
    real.modal.height = curRadio * parentHeight;
    real.modal.width = parentHeight * scaleI;
    real.img.width = '100%';
  } else if (scaleC < scaleI) {
    real.modal.width = curRadio * parentWidth;
    real.modal.height = parentWidth / scaleI;
    real.img.width = '100%';
  } else {
    real.modal.width = curRadio * parentWidth;
    real.modal.height = parentWidth / scaleI;
    real.img.width = '100%';
  }
  return real;
};

export const isEmptyOrNil = (value: unknown) => {
  return isEmpty(value) || isNil(value);
};

export const isNaNOrNull = (value: number) => {
  return isNaN(value) || isNull(value);
};
export const getUuid = () => {
  let timestamp = new Date().getTime();
  let perfOrNow =
    (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let random = Math.random() * 16;
    if (timestamp > 0) {
      random = (timestamp + random) % 16 | 0;
      timestamp = Math.floor(timestamp / 16);
    } else {
      random = (perfOrNow + random) % 16 | 0;
      perfOrNow = Math.floor(perfOrNow / 16);
    }
    return (c === 'x' ? random : (random & 0x3) | 0x8).toString(16);
  });
};

export const formatInputFun = (value: string) => {
  const curValue = value || '';
  return curValue.replace(/\n/g, '<br />');
};

export const formatInputTextNode = (value: string) => {
  const curValue = value || '';
  const textNode = document.createTextNode(curValue);
  const div = document.createElement('div');
  div.append(textNode);
  return div.innerHTML;
};

export const isProdEnv = () => {
  return window.location.origin.startsWith('https://performance.thoughtworks.net');
};

export function getSortOrder(
  searchFilter: { sortColumn: string; sortOrder: string | number },
  sorColumn: string,
) {
  return {
    ...DEFAULT_TABLE_COLUMN_SORT_CONFIG,
    key: sorColumn,
    sortOrder:
      searchFilter.sortColumn === sorColumn && SORT_DIRECTION_FOR_FE[searchFilter.sortOrder],
  };
}

export const onEnter = (e: React.KeyboardEvent, method: unknown) => {
  const methodPara = method as () => void;
  if (e.key === 'Enter') {
    methodPara();
  }
};

export const filterRichText = (html: string) => {
  const widthAttributeRegex = /width="[^"]*"/gi;
  const widthStyleRegex = /width\s*:\s*[^;]+;/gi;
  const classRegex = /class="[^"]*"/gi;
  const imgRegex = /<\/?(img)[^>]*>/g;
  if (isEmpty(html)) {
    return html;
  }
  return html
    .replace(widthAttributeRegex, '')
    .replace(widthStyleRegex, '')
    .replace(classRegex, '')
    .replace(imgRegex, '');
};
