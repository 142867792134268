import { createSlice } from '@reduxjs/toolkit';
import { get, isEmpty } from 'lodash';
import {
  ICalibrateIndicators,
  IState,
} from '@@/_new_src_/store/calibrateIndicatorsSlice/interface';
import {
  getCanCalibrateCycleAsync,
  postAssessmentRecordsAsync,
  postCalibrateValidationAsync,
} from '@@/_new_src_/store/calibrateIndicatorsSlice/asyncThunk';
import { CYCLE_CALIBRATE_STAGE, INITIAL_ASYNC_STATUS } from '@@/_new_src_/constants/common';
import { initialUploadFileError } from '@@/_new_src_/constants/calibrateIndicatorsEnum';

const initialState: ICalibrateIndicators = {
  calibrateValidationResponse: {
    ...INITIAL_ASYNC_STATUS,
    ...initialUploadFileError,
  },
  uploadAssessmentRecordsResponse: {
    ...INITIAL_ASYNC_STATUS,
    ...initialUploadFileError,
  },
  canCalibrateCycleResponse: {
    cycleCalibrateStage: CYCLE_CALIBRATE_STAGE.DEFAULT,
    getCanCalibrateCycleLoading: false,
    getCanCalibrateCycleSuccess: false,
  },
};

const calibrateIndicatorsSlice = createSlice({
  name: 'calibrateIndicators',
  initialState: { ...initialState },
  reducers: {
    setCalibrateValidationResponse: (state, action) => {
      const currentState = isEmpty(action.payload)
        ? initialState.calibrateValidationResponse
        : state.calibrateValidationResponse;
      state.calibrateValidationResponse = {
        ...currentState,
        ...action.payload,
      };
    },
    setUploadAssessmentRecordsResponse: (state, action) => {
      const currentState = isEmpty(action.payload)
        ? initialState.uploadAssessmentRecordsResponse
        : state.uploadAssessmentRecordsResponse;

      state.uploadAssessmentRecordsResponse = {
        ...currentState,
        ...action.payload,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(postCalibrateValidationAsync.pending, state => {
        state.calibrateValidationResponse = {
          ...initialState.calibrateValidationResponse,
          loading: true,
        };
      })
      .addCase(postCalibrateValidationAsync.fulfilled, (state, action) => {
        state.calibrateValidationResponse = {
          ...initialState.calibrateValidationResponse,
          success: true,
          ...action.payload,
        };
      })
      .addCase(postCalibrateValidationAsync.rejected, state => {
        state.calibrateValidationResponse = {
          ...initialState.calibrateValidationResponse,
          rejected: true,
        };
      })
      .addCase(postAssessmentRecordsAsync.pending, state => {
        state.uploadAssessmentRecordsResponse = {
          ...initialState.uploadAssessmentRecordsResponse,
          loading: true,
        };
      })
      .addCase(postAssessmentRecordsAsync.fulfilled, (state, action) => {
        state.uploadAssessmentRecordsResponse = {
          ...initialState.uploadAssessmentRecordsResponse,
          success: true,
          ...action.payload,
        };
      })
      .addCase(postAssessmentRecordsAsync.rejected, (state, action) => {
        const errorData = get(action, 'payload.data.details', {});
        state.uploadAssessmentRecordsResponse = {
          ...initialState.uploadAssessmentRecordsResponse,
          rejected: true,
          ...errorData,
        };
      })
      .addCase(getCanCalibrateCycleAsync.pending, state => {
        state.canCalibrateCycleResponse = {
          ...initialState.canCalibrateCycleResponse,
          getCanCalibrateCycleLoading: true,
          getCanCalibrateCycleSuccess: false,
        };
      })
      .addCase(getCanCalibrateCycleAsync.fulfilled, (state, action) => {
        state.canCalibrateCycleResponse = {
          ...initialState.canCalibrateCycleResponse,
          getCanCalibrateCycleLoading: false,
          getCanCalibrateCycleSuccess: true,
          cycleCalibrateStage: action.payload,
        };
      })
      .addCase(getCanCalibrateCycleAsync.rejected, state => {
        state.canCalibrateCycleResponse = {
          ...initialState.canCalibrateCycleResponse,
          getCanCalibrateCycleLoading: false,
          getCanCalibrateCycleSuccess: false,
        };
      });
  },
});

export const calibrateIndicatorsStore = (state: IState) => state.calibrateIndicators;
export const canCalibrateCycleStore = (state: IState) => {
  const canCalibrateCycleResponse = state.calibrateIndicators.canCalibrateCycleResponse;
  const cycleCalibrateStage = canCalibrateCycleResponse.cycleCalibrateStage;
  return {
    inH1CycleCalibrateStage: cycleCalibrateStage === CYCLE_CALIBRATE_STAGE.H1_CYCLE_CALIBRATE_STAGE,
    inH2CycleCalibrateStage: cycleCalibrateStage === CYCLE_CALIBRATE_STAGE.H2_CYCLE_CALIBRATE_STAGE,
    inCycleCalibrateStage: cycleCalibrateStage !== CYCLE_CALIBRATE_STAGE.DEFAULT,
    ...canCalibrateCycleResponse,
  };
};

export const { setCalibrateValidationResponse, setUploadAssessmentRecordsResponse } =
  calibrateIndicatorsSlice.actions;

export default calibrateIndicatorsSlice.reducer;
