import { Button } from 'antd';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { addReviewInput, updateReviewInput } from '@@/api/review';
import perfMessage from '../PerfMessage/perfMessage';
import perfModalConfirm from '../PerfModalConfirm';
import CustomPrompt from '../CustomPrompt';
import './index.less';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import TinyMceEditor from '@@/_new_src_/components/TinyMceEditor';
import { ASSESSMENT_INPUT_MAX_LENGTH } from '@@/common/constant/review';
import { TWER_I_SUPPORT_BY_AP_API, TWER_I_SUPPORT_BY_PP_API } from '@@/common/constant/matomo';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import cycleDetailLocale from '@@/_new_src_/local/cycleDetail/en_US';
import ConfirmModal from '@@/_new_src_/components/ConfirmModal';
import {
  confirmModalType,
  setConfirmModalStatus,
  setConfirmModalType,
} from '@@/_new_src_/store/commonSlice';
import { CONFIRM_MODAL_TYPE_INFO } from '@@/_new_src_/constants/common';
import { CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';

function ReviewInputEditor({
  cycleId,
  reviewInput,
  onReviewInputChange,
  onCancelReviewInput,
  onSubmitReviewInput,
  updatedMode,
  currentReviewInputId,
  reviewInputFromBackend,
}) {
  const { trackEvent } = useMatomo();
  const dispatch = useDispatch();

  const {
    aPSaveAssessment: { confirmModal: aPSaveAssessmentModalLocale },
  } = cycleDetailLocale;

  const currentConfirmModalType = useSelector(confirmModalType);

  const {
    currentCycleDetail: {
      createBySystem,
      isPerformancePartner,
      isAdditionalPartners,
      cycleVersion,
      isProbation,
    },
  } = useSelector(cycleDetailByVersion);

  const {
    reviewees: { reviewInputEditor: locale },
    confirmCancelModal,
  } = useSelector(selectLocaleResource);

  const [reviewInputHtmlInfo, setReviewInputHtml] = useState(null);
  const [reviewInputTextInfo, setReviewInputText] = useState(null);
  const [reviewInputInitialValue] = useState(reviewInput);

  const onCancel = useCallback(() => {
    if (reviewInput === reviewInputFromBackend) {
      onCancelReviewInput();
      return;
    }
    perfModalConfirm({
      centered: true,
      content: confirmCancelModal.confirmMessage,
      okText: confirmCancelModal.okText,
      cancelText: confirmCancelModal.cancelText,
      onOk: onCancelReviewInput,
    });
  }, [
    confirmCancelModal.cancelText,
    confirmCancelModal.confirmMessage,
    confirmCancelModal.okText,
    onCancelReviewInput,
    reviewInput,
    reviewInputFromBackend,
  ]);

  const submitConfirmModalLocale = useMemo(() => {
    if (createBySystem) {
      const confirmModalLocale = updatedMode
        ? aPSaveAssessmentModalLocale.update
        : aPSaveAssessmentModalLocale.submit;
      return {
        modalProps: {
          content: confirmModalLocale.content,
          okText: confirmModalLocale.okText,
          cancelText: confirmModalLocale.cancelText,
          title: confirmModalLocale.title,
        },
        successMessage: confirmModalLocale.successMessage,
      };
    } else {
      const confirmModalLocale = updatedMode ? locale.confirmSaveChanges : locale.confirmSubmit;
      return {
        modalProps: {
          content: confirmModalLocale.content,
          okText: confirmModalLocale.okText,
          cancelText: confirmModalLocale.cancelText,
        },
        successMessage: confirmModalLocale.successMessage,
      };
    }
  }, [createBySystem]);

  const handleConfirmOkFunc = useCallback(
    async hideModal => {
      if (updatedMode) {
        isPerformancePartner &&
          trackEvent({
            category: TWER_I_SUPPORT_BY_PP_API.category,
            action: TWER_I_SUPPORT_BY_PP_API.action.PP_EDITS_DRAFT_ASSESSMENT_SUMMARY,
          });
        isAdditionalPartners &&
          trackEvent({
            category: TWER_I_SUPPORT_BY_AP_API.category,
            action: TWER_I_SUPPORT_BY_AP_API.action.AP_EDITS_ASSESSMENT_INPUT,
          });
        await updateReviewInput(currentReviewInputId, reviewInputHtmlInfo).finally(() => {
          hideModal && hideModal();
        });
        onSubmitReviewInput(currentReviewInputId);
      } else {
        isPerformancePartner &&
          trackEvent({
            category: TWER_I_SUPPORT_BY_PP_API.category,
            action: TWER_I_SUPPORT_BY_PP_API.action.PP_ADDS_DRAFT_ASSESSMENT_SUMMARY,
          });
        isAdditionalPartners &&
          trackEvent({
            category: TWER_I_SUPPORT_BY_AP_API.category,
            action: TWER_I_SUPPORT_BY_AP_API.action.AP_ADDS_ASSESSMENT_INPUT,
          });
        const { data: id } = await addReviewInput(cycleId, reviewInputHtmlInfo).finally(() => {
          hideModal && hideModal();
        });
        onSubmitReviewInput(id);
      }
      perfMessage.success(submitConfirmModalLocale.successMessage);
    },
    [
      onSubmitReviewInput,
      cycleId,
      reviewInput,
      currentReviewInputId,
      updatedMode,
      reviewInputHtmlInfo,
      submitConfirmModalLocale,
    ],
  );

  const submitAssessmentConfirmModalProps = useMemo(() => {
    const {
      modalProps: { title, cancelText, okText, content },
    } = submitConfirmModalLocale;
    return {
      title: title,
      content: content,
      cancelText: cancelText,
      okText: okText,
      handleOkFunc: handleConfirmOkFunc,
    };
  }, [handleConfirmOkFunc, submitConfirmModalLocale]);

  const onSubmit = () => {
    if (createBySystem) {
      dispatch(setConfirmModalStatus(true));
      dispatch(setConfirmModalType(CONFIRM_MODAL_TYPE_INFO.AP_SUBMIT_ASSESSMENT));
    } else {
      const hideModal = perfModalConfirm({
        centered: true,
        width: 600,
        ...submitConfirmModalLocale.modalProps,
        onOk: () => handleConfirmOkFunc(hideModal),
      });
    }
  };

  const updateReviewInputHtml = html => {
    setReviewInputHtml(html);
    onReviewInputChange(html);
  };

  const updateReviewInputText = text => {
    setReviewInputText(text);
  };

  const renderAssessmentInputArea = () => {
    if (cycleVersion === CYCLE_VERSION.NEW && !isProbation) {
      return (
        <>
          <div className="hint" dangerouslySetInnerHTML={{ __html: locale.reviewInput.hint }}></div>
          <div className="tips" dangerouslySetInnerHTML={{ __html: locale.reviewInput.tips }}></div>
        </>
      );
    }
    return <></>;
  };

  return (
    <div className="component-review-input-editor">
      <CustomPrompt when={reviewInput !== reviewInputFromBackend} />
      <div className="review-input-title">{locale.reviewInput.label}</div>
      {renderAssessmentInputArea()}
      <TinyMceEditor
        initialValue={reviewInputInitialValue}
        updateHtmlInfo={updateReviewInputHtml}
        updateTextInfo={updateReviewInputText}
        maxLength={ASSESSMENT_INPUT_MAX_LENGTH}
      />
      <div className="operation">
        <Button className="cancel" onClick={onCancel}>
          {locale.cancel}
        </Button>
        <Button
          type="primary"
          className="submit"
          onClick={onSubmit}
          disabled={
            !reviewInputTextInfo || reviewInputTextInfo?.length > ASSESSMENT_INPUT_MAX_LENGTH
          }
        >
          {updatedMode ? locale.saveChanges : locale.submit}
        </Button>
      </div>
      {createBySystem &&
        currentConfirmModalType === CONFIRM_MODAL_TYPE_INFO.AP_SUBMIT_ASSESSMENT && (
          <ConfirmModal {...submitAssessmentConfirmModalProps} />
        )}
    </div>
  );
}

ReviewInputEditor.defaultProps = {
  updatedMode: false,
  review: {
    id: '',
  },
  reviewInput: '',
  onCancelReviewInput: () => {},
  onSubmitReviewInput: () => {},
};
export default memo(ReviewInputEditor);
